var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("the-layout-header", { attrs: { title: "Join us" } }),
      _c("div", { staticClass: "py-7" }),
      _c(
        "v-container",
        [
          _c(
            "v-sheet",
            { staticClass: "mx-auto", attrs: { "max-width": "700px" } },
            [
              _c("app-block-title", {
                attrs: { "no-page-title": "", data: _vm.titleData },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c("div", { staticClass: "py-5" }),
      _c(
        "v-container",
        { staticClass: "px-0" },
        [
          _c(
            "v-row",
            { staticClass: "mx-0" },
            _vm._l(_vm.team, function (t) {
              return _c(
                "v-col",
                {
                  key: t.key,
                  staticClass: "px-0 text-center",
                  attrs: { cols: "12", md: "6", lg: "4" },
                },
                [
                  _c("v-img", {
                    staticStyle: { "background-color": "#79e2e1" },
                    attrs: { src: t.img },
                  }),
                  _c("div", { staticClass: "mt-6 title" }, [
                    _vm._v(_vm._s(t.name)),
                  ]),
                  _c(
                    "div",
                    { staticClass: "grey--text text--lighten-1 mt-1" },
                    [_vm._v(_vm._s(t.title))]
                  ),
                  _c("div", { staticClass: "py-6" }),
                ],
                1
              )
            }),
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }